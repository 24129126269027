import { Button, Icon, Link, Tag } from 'components/ui'
import {
  ClinicServicesStoryblok,
  ServiceEntryStoryblok,
} from 'lib/storyblok/types'
import { getAnchorFromCmsLink, textByLine } from 'lib/utils/content'

import { StoryblokImage } from '../StoryblokImage'

type Props = {
  block: ClinicServicesStoryblok
}

export const ClinicServices = ({ block }: Props): JSX.Element => {
  const { theme, tag, title, description, link, service } = block

  return (
    <div
      className="bg-background"
      data-theme={theme === 'dark' ? 'dark' : 'light'}
    >
      <section className="max-w-screen-desktop mx-auto flex flex-col lg:flex-row px-5 lg:px-20 py-10 lg:py-15">
        <div className="flex flex-col lg:justify-between lg:max-w-[25.8125rem] lg:mr-15">
          <div className="flex flex-col gap-4">
            {tag && <Tag>{tag}</Tag>}
            {title && (
              <h3 className="text-title-medium lg:text-title-large font-regular text-foreground">
                {title}
              </h3>
            )}
          </div>
          <div className="mt-5 lg:mt-0">
            {description && (
              <div className="mb-8 lg:mb-5">
                {textByLine(description, (part) => (
                  <p className="text-eighteen lg:text-twenty font-light text-foreground/80">
                    {part}
                  </p>
                ))}
              </div>
            )}
            {link && link[0].label && link[0].link && (
              <Button
                as="a"
                variant="outline"
                rel={getAnchorFromCmsLink(link[0].link).rel}
                href={getAnchorFromCmsLink(link[0].link).href}
                target={getAnchorFromCmsLink(link[0].link).target}
                className="hidden lg:block pr-4"
              >
                <div className="flex items-center gap-2.5">
                  {link[0].label}
                  <Icon icon="arrow-right" className="opacity-60" />
                </div>
              </Button>
            )}
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-5 lg:w-full">
          <Card item={service[0]} />
          <div className="flex flex-col gap-4 lg:gap-5 flex-1">
            {service.slice(1, 3).map((item) => (
              <Card key={item._uid} item={item} />
            ))}
          </div>
        </div>
        {link && link[0].label && link[0].link && (
          <Button
            as="a"
            variant="outline"
            rel={getAnchorFromCmsLink(link[0].link).rel}
            href={getAnchorFromCmsLink(link[0].link).href}
            target={getAnchorFromCmsLink(link[0].link).target}
            className="mt-5 lg:hidden pr-4"
          >
            <div className="flex items-center gap-2.5">
              {link[0].label}
              <Icon icon="arrow-right" className="opacity-60" />
            </div>
          </Button>
        )}
      </section>
    </div>
  )
}

type CardProps = {
  item: ServiceEntryStoryblok
}

const Card = ({ item }: CardProps) => {
  const { image, title, description, link } = item
  const { href, target } = getAnchorFromCmsLink(link[0].link)

  const handleClick = () => {
    if (target === '_blank') {
      window.open(href, '_blank', 'noopener,noreferrer')
    } else {
      window.location.href = href || ''
    }
  }

  return (
    <div
      className="flex flex-col items-start w-full h-full rounded-3 bg-background-dark flex-1 text-foreground"
      onClick={handleClick}
      role="button"
      tabIndex={0}
    >
      {image?.filename && (
        <div className="hidden lg:block lg:relative lg:overflow-hidden lg:w-full lg:h-52">
          <StoryblokImage
            fill
            asset={image}
            width={undefined}
            height={undefined}
            className="object-cover"
          />
        </div>
      )}
      <div className="p-4 flex flex-col flex-1 justify-between items-start w-full">
        <div className="lg:mb-5 gap-1 lg:gap-0.5s">
          <p className="text-twenty font-regular">{title}</p>
          <p className="text-eighteen text-foreground lg:text-foreground/80">
            {description}
          </p>
        </div>
        <div className="hidden lg:block">
          <Link href={getAnchorFromCmsLink(link).href} arrow>
            {link[0].label}
          </Link>
        </div>
      </div>
    </div>
  )
}
